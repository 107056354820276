import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "../components/Admin/AdminLayout";
import AddUsers from "../components/Admin/AddUsers";
import { useUser } from "../hooks/useUser";
import AllUsers from "src/components/Admin/AllUsers";

const AdminRoutes: React.FC = () => {
  const { token, role } = useUser(); // Get token and role from useUser hook

  // Only render AdminLayout if token is valid and role is 'admin'
  // if (token && role === 'admin') {
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index path="adduser" element={<AddUsers />} />
        <Route index path="allusers" element={<AllUsers />} />
        {/* You can add more routes inside AdminLayout here */}
      </Route>
    </Routes>
  );
  // }

  // Redirect or handle non-admin users
  // return <Navigate to="/not-authorized" />;
};

export default AdminRoutes;
