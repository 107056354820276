import React, { useState, useEffect } from "react";
import MadaiForm from "./MadaiForm.components";
import { IoBarChartSharp } from "react-icons/io5";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import Video from "./Video.components";
import { useNavigate } from "react-router-dom"; // For redirection
import CloudCard from "./Cards/CloudCard";
import PolarCard from "./Cards/PolarCard";
import SumCard from "./Cards/SumCard";
import RadarGraph from "./Graphs/Radar";
import WorldGraph from "./Graphs/WorldCloud";
import SumGraph from "./Graphs/SumofEmotion";

interface ChatBoxMainprops {
  children?: React.ReactNode;
  className?: string;
}

const ChatBox: React.FC<ChatBoxMainprops> = ({ children, className }) => {
  const [activeSection, setActiveSection] = useState<"form" | "graph" | null>(
    "form"
  );
  const [videoFilename, setVideoFilename] = useState<string | null>(null);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [isTooltipVisibleGraph, setIsTooltipVisibleGraph] = useState(false);
  const [isTooltipVisibleFile, setIsTooltipVisibleFile] = useState(false);
  const [isTokenExpiredPopupVisible, setIsTokenExpiredPopupVisible] =
    useState(false); // New state for pop-up visibility
  const [graphs, setGraphs] = useState<any[]>([]); // Graph pop-up visibility
  const [selectedGraph, setSelectedGraph] = useState<any>(null); // Selected graph content
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate(); // React Router's navigate hook for redirection

  const handleShowForm = () => setActiveSection("form");
  const handleShowGraph = () => setActiveSection("graph");

  const handleVideoUpload = (filename: string) => setVideoFilename(filename);

  const handleGraphClick = (graph: any) => {
    setSelectedGraph(graph); // Set the clicked graph as the selected graph
    setOpen(true); // Show the pop-up
  };

  const closeGraphPopup = () => {
    setOpen(false); // Close the pop-up
    setSelectedGraph(null); // Clear the selected graph
  };

  useEffect(() => {
    const storedVideoFilename = localStorage.getItem("uploadedVideoFilename");
    const storedVideoId = localStorage.getItem("videoId");
    if (storedVideoFilename) {
      setVideoFilename(storedVideoFilename);
    }
    if (storedVideoId) {
      setVideoId(storedVideoId);
    }

    const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
    if (!token || isTokenExpired(token)) {
      setIsTokenExpiredPopupVisible(true); // Show pop-up if token is expired
    }
  }, []);

  const isTokenExpired = (token: string): boolean => {
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode the JWT token
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp < currentTime; // Check if token is expired
    } catch (error) {
      console.error("Failed to decode token:", error);
      return true; // Treat as expired if decoding fails
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  const cards = [
    { component: <CloudCard />, graph: <WorldGraph /> },
    { component: <PolarCard />, graph: <RadarGraph /> },
    { component: <SumCard />, graph: <SumGraph /> },
  ];

  return (
    <div className="flex flex-row h-screen overflow-hidden">
      <div className="flex flex-col h-full w-3/4 bg-white p-2 items-center justify-center">
        <div className="flex flex-col p-2 justify-center items-center font-bold h-[5%]">
          <h4 className="text-[1.5rem] uppercase font-[4rem]">
            Mad<span className="text-red-800">AI</span>
          </h4>
        </div>
        <div className={`flex-col flex  w-[85%] h-[95%] bg ${className || ""}`}>
          {children}
        </div>
      </div>

      <div className="flex-col flex w-1/4 bg-gray-100 p-2 h-full">
        <div>
          {videoId ? (
            <Video videoId={videoId} />
          ) : (
            <video controls width="640" height="360">
              No video available
            </video>
          )}
        </div>

        <div className="flex p-2 h-[8%]">
          <div className="absolute right-4 px-2 py-1 hover:bg-gray-200 rounded-md">
            <button
              className="relative pt-1"
              onMouseEnter={() => setIsTooltipVisibleFile(true)}
              onMouseLeave={() => setIsTooltipVisibleFile(false)}
              onClick={handleShowForm}
            >
              <MdOutlineDriveFolderUpload size={20} />
            </button>
            {isTooltipVisibleFile && (
              <div className="absolute bottom-2 right-[100%] transform -translate-x-[10px] px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap">
                File Upload
              </div>
            )}
          </div>
          <div className="absolute px-2 py-1 hover:bg-gray-200 rounded-md">
            <button
              className="relative pt-1"
              onMouseEnter={() => setIsTooltipVisibleGraph(true)}
              onMouseLeave={() => setIsTooltipVisibleGraph(false)}
              onClick={handleShowGraph}
            >
              <IoBarChartSharp size={20} />
            </button>
            {isTooltipVisibleGraph && (
              <div className="absolute bottom-2 right-[100%] transform -translate-x-[10px] px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap">
                Graphs
              </div>
            )}
          </div>
        </div>

        <div className="flex-col flex h-[85%] bg-neutral-500 overflow-hidden">
          <div style={{ display: activeSection === "form" ? "" : "none" }}>
            <MadaiForm onVideoUpload={handleVideoUpload} />
          </div>
          <div
            style={{ display: activeSection === "graph" ? "" : "none" }}
            className="overflow-y-auto h-full p-2"
          >
            {cards.map((card, index) => (
              <div
                key={index}
                className="mb-4 cursor-pointer"
                onClick={() => handleGraphClick(card.graph)}
              >
                {card.component}
              </div>
            ))}
          </div>
        </div>
      </div>

      {open && selectedGraph && (
        <div
          className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60"
          onClick={closeGraphPopup}
        >
          <div
            className="absolute p-0 h-[83%] w-[60rem]"
            onClick={(e) => e.stopPropagation()} // Prevent closing the dialog when clicking inside it
          >
            {/* Image section */}
            <div>
              {/* <SumGraph />
              <WorldGraph />
              <RadarGraph/> */}
              {/* {selectedGraph} */}
              {selectedGraph}
            </div>
          </div>
        </div>
      )}

      {isTokenExpiredPopupVisible && (
        <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Session Expired</h2>
            <p className="mb-4">Please log in again to continue.</p>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={navigateToLogin}
            >
              Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;

