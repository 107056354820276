import { data } from "jquery";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

interface IFormInput {
  companyname: string;
  name: string;
  email: string;
  password: string;
  role: string;
}

const AddUsers: React.FC = () => {
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  const [role, setRole] = useState("");

  const [isSignUpButtonClicked, setIsSignUpButtonClicked] = useState(false);
  const [showSignUpPassword, setShowSignUpPassword] = useState(false); // State to toggle SignUp password visibility
  const signUp = () => {
    setIsSignUpButtonClicked(!isSignUpButtonClicked);
  };
  const {
    register: registerSignUp,
    handleSubmit: handleSubmitSignUp,
    setValue,
    formState: { errors: errorsSignUp },
    reset,
  } = useForm<IFormInput>();

  const onSignUpSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY"); // Retrieve the token
      console.log(token);

      if (token) {
        const response = await fetch(
          "https://madai.app/addusers/api/admin/adduser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(token)}`, // Ensure token is passed as a Bearer token
            },
            body: JSON.stringify({
              company_name: data.companyname,
              name: data.name,
              email: data.email,
              password: data.password,
              role: data.role,
            }),
          }
        );

        if (response.ok) {
          toast.success("User is registered successfully !!");
          reset();
        } else {
          const errorData = await response.json();
          handleErrorMessageForRegistration(errorData, setValue, reset);
          toast.error("Registration failed. Please try again.");
        }
      }
    } catch (error) {
      console.error("Registration failed:", error);
      toast.error("An error occurred during registration.");
    }
  };

  const handleErrorMessageForRegistration = (
    errorData: any,
    setFieldValue: any,
    reset: any
  ) => {
    if (errorData.message === "User with this email already exists") {
      toast.error("User with this email already exists", {
        position: "top-center",
      });
      setFieldValue("email", ""); // Reset the email input field only
    } else if (errorData.message === "User with this name already exists") {
      toast.error("User with this name already exists.", {
        position: "top-center",
      });
      setFieldValue("name", ""); // Reset the name input field only
    } else {
      toast.error("An unknown error occurred");
      reset(); // Optional: reset the whole form if needed
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <ToastContainer />
      <form
        onSubmit={handleSubmitSignUp(onSignUpSubmit)}
        className="p-4 bg-white rounded-lg shadow-md w-[50%]"
      >
        <div className="mb-2">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
            Company Name
          </label>
          <input
            type="text"
            id="companyname"
            placeholder="Company Name"
            className={`w-full p-2 bg-gray-200 rounded ${
              isSignUpButtonClicked ? "my-0" : "my-1"
            }`}
            {...registerSignUp("companyname", {
              required: "Company name is required",
              maxLength: {
                value: 60,
                message: "Company name cannot exceed 60 characters",
              },
            })}
          />
          {errorsSignUp.companyname && (
            <span style={{ color: "red", fontSize: "8px" }}>
              {errorsSignUp.companyname.message}
            </span>
          )}
        </div>
        <div className="mb-2">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="signup-name"
            placeholder="Name"
            className={`w-full p-2 bg-gray-200 rounded ${
              isSignUpButtonClicked ? " my-0" : "my-1"
            }`}
            {...registerSignUp("name", {
              required: "Username is required",
              maxLength: {
                value: 30,
                message: "Username cannot exceed 30 characters",
              },
            })}
          />
          {errorsSignUp.name && (
            <span style={{ color: "red", fontSize: "8px" }}>
              {errorsSignUp.name.message}
            </span>
          )}
        </div>

        <div className="mb-2">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            placeholder="Email"
            id="signup-email"
            className={`w-full p-2 bg-gray-200 rounded ${
              isSignUpButtonClicked ? " my-0" : "my-1"
            }`}
            {...registerSignUp("email", {
              required: "Email is required",
              maxLength: {
                value: 60,
                message: "Email cannot exceed 60 characters",
              },
            })}
          />
          {errorsSignUp.email && (
            <span style={{ color: "red", fontSize: "8px" }}>
              {errorsSignUp.email.message}
            </span>
          )}
        </div>

        <div className="mb-2">
          <label
            htmlFor="password"
            className="block text-gray-700 font-bold mb-2"
          >
            Password
          </label>
          <input
            type={showSignUpPassword ? "text" : "password"}
            id="signup-password"
            placeholder="Password"
            className={`w-full p-2 bg-gray-200 rounded ${
              isSignUpButtonClicked ? " my-0" : "my-1"
            }`}
            {...registerSignUp("password", {
              required: "Password is required",
              maxLength: {
                value: 14,
                message: "Password cannot exceed 14 characters",
              },
            })}
          />
          {errorsSignUp.password && (
            <span style={{ color: "red", fontSize: "8px" }}>
              {errorsSignUp.password.message}
            </span>
          )}
        </div>

        <div
          className={`w-full space-x-1 p-2 flex rounded ${
            isSignUpButtonClicked ? " my-0" : "my-0"
          }`}
        >
          <input
            type="checkbox"
            onChange={() => setShowSignUpPassword(!showSignUpPassword)}
          />{" "}
          <span className="text-xs ">Show Password</span>
        </div>

        <div className="mb-2">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="role">
            Role
          </label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          >
            <option value="">Select role</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded"
          onClick={signUp}
        >
          Add User
        </button>
      </form>
    </div>
  );
};

export default AddUsers;
