import React from 'react';
import Tagline from './design/Tagline';


interface HeadingProps {
    className?: string;
    title?: string;
    text?: string;
    tag?: string;
}

const Heading: React.FC<HeadingProps> = ({ className, title, text, tag }) => {
  return (
    <div className={`${className} max-w-[50rem] mx-auto mb-12 lg:mb-20`}>
      {tag && <Tagline className="mb-4 md:justify-center">{tag}</Tagline>}

      {title && (
        <h2 className="h4 pl-16 max-w-[50rem] mx-auto mb-12 lg:mb-20 md:text-center font-bold">
          {title}
        </h2>
      )}
      {text && (
        <p className="body-2 -mt-[4rem] ml-[8.5rem] text-n-4 font-bold">
          {text}
        </p>
      )}

      {/* <div className="absolute left-16 -top-10 
                        w-5 h-5 bg-gradient-to-b from-[#5a3b31] to-[#5e5e64] 
                        rounded-full lg:left-32 xl:left:10" style={{ boxShadow: '1px 1px 8px 12px rgba(255, 0, 0, 0.8)' }}/> */}
    </div>
  );
};

export default Heading;
