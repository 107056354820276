import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { EmotionDataProvider } from "./context/EmotionDataContext"; // Import the context provider
import Header from "./components/Home/Header.component";
import HomeRoutes from "./routes/HomeRoutes";
import MadaiRoutes from "./routes/MadaiRoutes";
import TempLogin from "./components/Authentication/TempLogin.components";
import AdminRoutes from "./routes/AdminRoutes";
import AuthForm from "./components/Authentication/SignInUp.components";
//import ToothColorDetection from "./components/Teeth/TeethCam";
//import Result from "./components/Teeth/Result";
//import VideoCapture from "./components/Hair/HairCare";
//import HairHealthResults from "./components/Hair/HairResult";
//import JobNo from "./components/Job Number/JobNumber";
import Waveform from "./components/MadAI/Graphs/Waveform";
import Radar from "./components/MadAI/Graphs/Radar";
import WorldCloud from "./components/MadAI/Graphs/WorldCloud";
import SumofEmotion from "./components/MadAI/Graphs/SumofEmotion";
import Card from "./components/MadAI/Cards/CloudCard";
import DetectFace from "./components/HNY/DetectFace";

function App() {
  const location = useLocation();

  // Check if the current path is the homepage
  const isHomePage = location.pathname === "/";

  return (
    <EmotionDataProvider>
      <div className={isHomePage ? "pt-[9.75rem] lg:pt-[8.75rem]" : ""}>
        {/* Render Header only on the homepage */}
        {isHomePage && <Header />}

        <Routes>
          {/* Route for home */}
          <Route path="/" element={<HomeRoutes />} />

          {/* Route for Madai section */}
          <Route path="/madai/*" element={<MadaiRoutes />} />

          {/* Protected admin routes */}
          <Route path="/admin/*" element={<AdminRoutes />} />

          {/* Route for login */}
         <Route
            path="/login"
            element={
              <TempLogin
                id="exampleFormControlInput1"
                label="Example label"
                placeholder="Example label"
              />
            }
          />

         {/* <Route path="/login" element={<AuthForm/>}/> */}

          {/* Routes for other sections */}
         {/* <Route path="/teeth" element={<ToothColorDetection />} />
          <Route path="/result" element={<Result />} />
          <Route path="/hair" element={<VideoCapture />} />
          <Route path="/hairresult" element={<HairHealthResults />} />
          <Route path="/jobnumber" element={<JobNo />} />*/}
          <Route path="/newyearwish" element={<DetectFace/>}/>
          <Route path="/graph" element={<Waveform />} />
          <Route path="/radar" element={<Radar />} />
          <Route path="/cloud" element={<WorldCloud />} />
          <Route path="/sum" element={<SumofEmotion />} />
          <Route path="/card" element={<Card />} />
        </Routes>
      </div>
    </EmotionDataProvider>
  );
}

export default App;

