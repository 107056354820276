import { useState, useEffect, useRef } from 'react';

interface UserInformation {
  userid: string;
  videoId: string;
  videoTitle: string;
  uploadedVideoID: string;
  token: string | null;
  role: string | null;
}

export const useUser = (): UserInformation => {
  const [userid, setUserId] = useState<string>('');
  const [videoId, setVideoId] = useState<string>('');
  const [uploadedVideoID, setUploadedvideoID] = useState<string>('');
  const [videoTitle, setVideoTitle] = useState<string>('');
  const [token, setToken] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);

  // Reference to track the previous userid
  const previousUserIdRef = useRef<string | null>(null);

  useEffect(() => {
    // Fetch stored user information from localStorage
    const storedUserId = localStorage.getItem('user_id') || '';
    const storedVideoId = localStorage.getItem('videoId') || '';
    const storeUploadedVideoID = localStorage.getItem('uploadedVideoID') || '';
    const storedVideoTitle = localStorage.getItem('videoTitle') || '';
    const storedToken = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
    const storedRole = localStorage.getItem('user_role');

    // Set the current user data in the state
    setUserId(storedUserId);
    setVideoId(storedVideoId);
    setUploadedvideoID(storeUploadedVideoID);
    setVideoTitle(storedVideoTitle);
    setToken(storedToken);
    setRole(storedRole);

    // Check if there's a new user logged in
    if (previousUserIdRef.current && previousUserIdRef.current !== storedUserId) {
      // New user detected, reset local storage values related to the previous user
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('chatCount-') || key.startsWith('videoId-') || key.startsWith('videoTitle-')) {
          localStorage.removeItem(key);
        }
      });

      // Reset the state for the new user
      setVideoId('');
      setVideoTitle('');
    }

    // Set a timer to clear videoId after 2 minutes (120000 milliseconds)
    // if (storedVideoId) {
    //   const videoExpirationTimer = setTimeout(() => {
    //     localStorage.removeItem('videoId');
    //     setVideoId(''); // Clear the state as well
    //   }, 120000); // 2 minutes

    //   // Clean up the timer if the component unmounts or videoId changes
    //   return () => clearTimeout(videoExpirationTimer);
    // }

    // Update the reference to track the current user ID
    previousUserIdRef.current = storedUserId;
  }, [userid, videoId]);  // Add `userid` and `videoId` to the dependencies

  return { userid, videoId, uploadedVideoID, videoTitle, token, role };
};

