import React, { useState } from "react";
import { PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  ChartOptions,
} from "chart.js";

import { useEmotionData } from "../../../context/EmotionDataContext";

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale);

interface EmotionData {
  [emotion: string]: {
    [second: string]: number;
  };
}

const emotionColors: { [key: string]: string } = {
  "Adbhuta (Astonishment)": "#800080",
  "Bibhatsa (Disgust)": "#A52A2A",
  "Bhayanaka (Fear)": "#000000",
  "Hasya (Joy)": "#9ACD32",
  "Karuna (Sorrow)": "#87CEEB",
  "Roudra (Anger)": "#FF0000",
  "Santa (Peace)": "#0000FF",
  "Srungara (Love)": "#FFC0CB",
  "Veera (Confidence)": "#FFD700",
};

const PolarAreaChart: React.FC = () => {
  const { emotionData }: { emotionData: EmotionData | null } = useEmotionData();
  const [selectedSeconds, setSelectedSeconds] = useState<string[]>(["all"]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  if (!emotionData) {
    return <p>No emotion data available.</p>;
  }

  const seconds = Object.keys(emotionData[Object.keys(emotionData)[0]]);

  // Toggle selection for a specific second
  const toggleSecond = (second: string) => {
    if (selectedSeconds.includes("all")) {
      setSelectedSeconds([second]);
    } else if (selectedSeconds.includes(second)) {
      setSelectedSeconds(selectedSeconds.filter((s) => s !== second));
    } else {
      setSelectedSeconds([...selectedSeconds, second]);
    }
  };

  // Toggle "Select All" option
  const toggleSelectAll = () => {
    setSelectedSeconds(["all"]);
  };

  // Prepare chart labels and datasets
  let chartLabels: string[] = [];
  let chartDatasets: any[] = [];

  if (selectedSeconds.includes("all") || selectedSeconds.length === 0) {
    // "Select All" case or no seconds selected
    chartLabels = seconds;
    chartDatasets = Object.keys(emotionData).map((emotion) => ({
      label: emotion,
      data: seconds.map((second) => emotionData[emotion][second] || 0),
      backgroundColor: emotionColors[emotion],
    }));
  } else {
    // Specific seconds selected
    chartLabels = Object.keys(emotionData);
    chartDatasets = [
      {
        label: `Emotions for selected seconds`,
        data: chartLabels.map((emotion) =>
          selectedSeconds.reduce(
            (sum, second) => sum + (emotionData[emotion][second] || 0),
            0
          )
        ),
        backgroundColor: chartLabels.map((emotion) => emotionColors[emotion]),
      },
    ];
  }

  const chartData = {
    labels: chartLabels,
    datasets: chartDatasets,
  };

  const options: ChartOptions<"polarArea"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          generateLabels: (chart) => {
            if (
              chart.data.datasets &&
              chart.data.datasets[0] &&
              Array.isArray(chart.data.datasets[0].backgroundColor)
            ) {
              return chart.data.labels!.map((label, index) => ({
                text: label as string,
                fillStyle: (chart.data.datasets[0].backgroundColor as string[])[
                  index
                ],
              }));
            }
            return [];
          },
          font: {
            size: 14,
          },
          color: "white",
        },
      },
    },
    scales: {
      r: {
        ticks: {
          display: false,
          color: "white",
          backdropColor: "#424242",
        },
        grid: {
          color: "white",
        },
        pointLabels: {
          display: true, // Always display the labels
          callback: (value, index) =>
            selectedSeconds.includes("all") ? seconds[index] + "s" : "", // Show seconds only when "Select All" is active
          font: {
            size: 14,
          },
          color: "white",
        },
      },
    },
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-900 p-4 rounded-lg shadow-lg">
      {/* <h2 className="text-white text-2xl font-semibold mb-4">
        Emotion Analysis Chart
      </h2> */}
      <div className="relative">
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="p-2 bg-gray-800 text-white rounded border border-gray-600 focus:outline-none left-0"
        >
          {selectedSeconds.includes("all")
            ? "Select All"
            : `Selected: ${selectedSeconds.join(", ")}s`}
        </button>
        {dropdownOpen && (
          <div className="absolute bg-gray-800 text-white mt-2 w-[15rem] rounded shadow-lg z-10 overflow-hidden">
            <div
              className="flex flex-col space-y-2 p-2 max-h-[200px] overflow-y-auto"
              style={{
                scrollbarWidth: "thin", // For Firefox
              }}
            >
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="time-range"
                  value="all"
                  checked={selectedSeconds.includes("all")}
                  onChange={toggleSelectAll}
                  className="mr-2"
                />
                Select All
              </label>
              {seconds.map((second) => (
                <label key={second} className="flex items-center">
                  <input
                    type="checkbox"
                    name="time-range"
                    value={second}
                    checked={selectedSeconds.includes(second)}
                    onChange={() => toggleSecond(second)}
                    className="mr-2"
                  />
                  {second}s
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex relative h-[40rem] w-[55rem] mt-6">
        <PolarArea
          data={chartData}
          options={options}
          className="bg-gray-800 h-auto rounded-md p-4"
        />
      </div>
    </div>
  );
};

export default PolarAreaChart;
