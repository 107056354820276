import React, { useEffect, useRef, useState } from "react";
import { Fireworks } from "fireworks-js";
import frame from "../../assets/1.jpg";
import frame2 from "../../assets/2.jpg";
import frame3 from "../../assets/3.jpg";
import frame4 from "../../assets/4.jpg";
import { Helmet } from "react-helmet";

const DetectFace = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const fireworksRef = useRef<HTMLDivElement | null>(null);
  const [emotionMessage, setEmotionMessage] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [detectionCompleted, setDetectionCompleted] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(5);
  const [backgroundImage, setBackgroundImage] = useState<string>(frame);
  const [showFireworks, setShowFireworks] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const frames = [frame2, frame3, frame4];

  const fireworksOptions = {
    hue: { min: 0, max: 345 },
    delay: { min: 15, max: 15 },
    rocketsPoint: { min: 40, max: 40 },
    speed: 5,
    acceleration: 1,
    friction: 0.96,
    gravity: 1,
    particles: 90,
    trace: 3,
    explosion: 6,
    autoresize: true,
    brightness: { min: 50, max: 80, decay: { min: 0.015, max: 0.03 } },
    boundaries: { visible: false },
    sound: {
      enabled: false,
      files: [
        "https://fireworks.js.org/sounds/explosion0.mp3",
        "https://fireworks.js.org/sounds/explosion1.mp3",
        "https://fireworks.js.org/sounds/explosion2.mp3",
      ],
      volume: { min: 1, max: 2 },
    },
    mouse: { click: true, move: false, max: 1 },
  };

  useEffect(() => {
    // Load persisted state from localStorage
    const savedBackgroundImage = localStorage.getItem("backgroundImage");
    const savedShowFireworks = localStorage.getItem("showFireworks");

    if (savedBackgroundImage) {
      setBackgroundImage(savedBackgroundImage);
    }

    if (savedShowFireworks === "true") {
      setShowFireworks(true);
    }
  }, []);

  const startFrameTransitions = async () => {
    for (let i = 0; i < frames.length; i++) {
      await new Promise((resolve) => {
        setTimeout(() => {
          setSuccessMessage(null); // Hide success message
          setBackgroundImage(frames[i]);
          localStorage.setItem("backgroundImage", frames[i]); // Save current frame
          if (i === frames.length - 1) {
            setShowFireworks(true);
            localStorage.setItem("showFireworks", "true"); // Save fireworks state
          }
          resolve(null);
        }, 4000);
      });
    }
  };

  useEffect(() => {
    if (showFireworks && fireworksRef.current) {
      const fireworks = new Fireworks(fireworksRef.current, fireworksOptions);
      fireworks.start();
      return () => fireworks.stop();
    }
  }, [showFireworks]);

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "user" },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera:", err);
      }
    };

    getMediaStream();
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  useEffect(() => {
    if (detectionCompleted) return;

    const interval = setInterval(async () => {
      if (videoRef.current && !detectionCompleted) {
        const canvas = document.createElement("canvas");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
          const frame = canvas.toDataURL("image/jpeg");
          const blob = await fetch(frame).then((res) => res.blob());

          const formData = new FormData();
          formData.append("frame", blob);

          try {
            const response = await fetch("https://madai.app/capture", {
              method: "POST",
              body: formData,
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(
                errorData.message || "Failed to process the frame."
              );
            }

            const data = await response.json();

            if (data.image_url) {
              setDetectionCompleted(true);
              setEmotionMessage(data.message);
              setImageUrl(data.image_url);
              setSuccessMessage("YOU'VE UNLOCKED A SURPRISE!");
              setErrorMessage(null);
              startFrameTransitions();
            } else {
              setEmotionMessage(`Detected Emotion: ${data.message}`);
              setImageUrl(null);
            }
          } catch (err: any) {
            console.error("Error sending frame:", err);
            if (countdown === 0) {
              setErrorMessage(err.message || "Unknown error occurred.");
              setSuccessMessage(null);
            }
          }
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [detectionCompleted, countdown]);

  return (
<>
          {/* Helmet for meta tags */}
          <Helmet>
        <title>Flip to Predict Your New Year</title>
        <meta property="og:title" content="Click to Predict Your New Year" />
        <meta property="og:description" content="Click to reveal your New Year prediction and share the joy!" />
        <meta property="og:image" content="https://madai.app/images/Ban-New-Year-Wish.jpg" />
        <meta property="og:url" content="https://madai.app/newyearwish" />
        <meta property="og:type" content="website" />
      </Helmet>
    <div
      className="flex flex-col items-center justify-center h-screen bg-cover bg-center transition-all duration-2000 ease-in-out"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div
        ref={fireworksRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
        }}
      />
      <div className="absolute top-0 left-0 w-full h-full -z-10"></div>
      <div className="relative flex flex-col items-center">
        {backgroundImage === frame && (
          <>
          <div>
              <span className="text-2xl text-brown-300 mb-4">
                SMILE TO UNLOCK A SURPRISE
              </span>
            </div>
            <div className="relative flex items-center justify-center mt-8 shadow-lg">
              {countdown > 0 ? (
                <p className="text-white text-3xl animate-pulse">
                  <span className="absolute mt-[2rem]">{countdown}</span>
                </p>
              ) : errorMessage ? (
                <p className="text-red-500 text-xl">{errorMessage}</p>
              ) : successMessage ? (
                <p className="text-brown-200 text-xl">{successMessage}</p>
              ) : null}
            </div>

            <div className="relative flex items-center justify-center mt-[7rem]">
              <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                className={`w-[18rem] h-[18rem] rounded-full border-4 shadow-lg ${
                  successMessage
                    ? "border-green-500"
                    : errorMessage
                    ? "border-red-500"
                    : "border-gray-200"
                }`}
              ></video>
            </div>
          </>
        )}
      </div>
    </div>
</>
  );
};

export default DetectFace;

