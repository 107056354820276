import React, { useState, useEffect } from "react";

interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  createdAt: string; // Assuming this is the creation date to filter by month
}

const AllUsers: React.FC = () => {
  // Sample list of users
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [monthFilter, setMonthFilter] = useState<string>("");

  // Fetch users from API or state
  useEffect(() => {
    // Example users data
    const fetchedUsers: User[] = [
      {
        id: 1,
        name: "John Doe",
        email: "john@example.com",
        role: "admin",
        createdAt: "2024-08-15",
      },
      {
        id: 2,
        name: "Jane Doe",
        email: "jane@example.com",
        role: "user",
        createdAt: "2024-09-10",
      },
      // Add more users here
    ];
    setUsers(fetchedUsers);
    setFilteredUsers(fetchedUsers);
  }, []);

  // Handle month filtering
  const handleMonthFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = e.target.value;
    setMonthFilter(selectedMonth);

    if (selectedMonth === "") {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter((user) => {
        const userMonth = new Date(user.createdAt).getMonth() + 1; // Get month from user creation date
        return userMonth === parseInt(selectedMonth, 10);
      });
      setFilteredUsers(filtered);
    }
  };

  // Handle delete user
  const handleDelete = (id: number) => {
    setUsers(users.filter((user) => user.id !== id));
    setFilteredUsers(filteredUsers.filter((user) => user.id !== id));
  };

  // Handle edit user (Example: Redirect to edit page or inline edit)
  const handleEdit = (id: number) => {
    console.log("Edit user with id:", id);
    // Logic for editing user goes here
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      {/* Month filter */}
      <div className="mb-4">
        <label
          htmlFor="month-filter"
          className="block text-gray-700 font-bold mb-2"
        >
          Filter by Month:
        </label>
        <select
          id="month-filter"
          value={monthFilter}
          onChange={handleMonthFilterChange}
          className="w-[5rem] px-2 py-1 border rounded"
        >
          <option value="">All</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>

      {/* User table */}
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border">Name</th>
            <th className="py-2 px-4 border">Email</th>
            <th className="py-2 px-4 border">Role</th>
            <th className="py-2 px-4 border">Register At</th>
            <th className="py-2 px-4 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <tr key={user.id}>
                <td className="py-2 px-4 border">{user.name}</td>
                <td className="py-2 px-4 border">{user.email}</td>
                <td className="py-2 px-4 border">{user.role}</td>
                <td className="py-2 px-4 border">
                  {new Date(user.createdAt).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border">
                  <button
                    onClick={() => handleEdit(user.id)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(user.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center py-4">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllUsers;
