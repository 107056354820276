import React, { createContext, useContext, useState, ReactNode } from "react";

type EmotionData = { [key: string]: { [time: number]: number } } | null;

interface EmotionDataContextType {
  emotionData: EmotionData;
  predictedRagaName: string | null;
  setEmotionData: React.Dispatch<React.SetStateAction<EmotionData>>;
  setPredictedRagaName: React.Dispatch<React.SetStateAction<string | null>>;
}

const EmotionDataContext = createContext<EmotionDataContextType | undefined>(
  undefined
);

export const EmotionDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [emotionData, setEmotionData] = useState<EmotionData>(() => {
    const storedData = localStorage.getItem("emotion_data");
    try {
      return storedData ? JSON.parse(storedData) : null;
    } catch (error) {
      console.error("Failed to parse emotion_data from localStorage:", error);
      return null;
    }
  });

  const [predictedRagaName, setPredictedRagaName] = useState<string | null>(() => {
    return localStorage.getItem("predicted_raga_name");
  });

  React.useEffect(() => {
    if (emotionData) {
      try {
        localStorage.setItem("emotion_data", JSON.stringify(emotionData));
      } catch (error) {
        console.error("Failed to stringify and save emotion_data:", error);
      }
    }
  }, [emotionData]);

  React.useEffect(() => {
    if (predictedRagaName) {
      localStorage.setItem("predicted_raga_name", predictedRagaName);
    }
  }, [predictedRagaName]);

  return (
    <EmotionDataContext.Provider
      value={{ emotionData, predictedRagaName, setEmotionData, setPredictedRagaName }}
    >
      {children}
    </EmotionDataContext.Provider>
  );
};

export const useEmotionData = () => {
  const context = useContext(EmotionDataContext);
  if (!context) {
    throw new Error(
      "useEmotionData must be used within an EmotionDataProvider"
    );
  }
  return context;
};
