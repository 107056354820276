import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

const uData: number[] = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const pData: number[] = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels: string[] = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];

const SimpleLineChart: React.FC = () => {
  return (
    <LineChart
      width={500}
      height={300}
      series={[
        // { data: pData, label: 'pv' },
        { data: uData, label: 'uv' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
    />
  );
};

export default SimpleLineChart;
