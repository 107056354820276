import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import MadAI from "../../../assets/MAD-03.png";
import { BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { SideBarCom } from 'src/constants';
import { IconType } from 'react-icons';

interface ChatHistoryProps {
  userId: string;
  uploadedVideoId?: string;
  videoTitle: string;
}

interface Conversation {
  id: number;
  name: string;
  timestamp: string;
  video_id: string;
  video_filename: string;
  messages: Array<{ role: string; message: string }>;
}

const Layout: React.FC<ChatHistoryProps> = ({ userId, uploadedVideoId, videoTitle }) => {
  const [expanded, setExpanded] = useState(true);
  const [active, setActive] = useState<string | null>(null);
  const [chatHistories, setChatHistories] = useState<Conversation[]>([]);
  const [selectedChat, setSelectedChat] = useState<Conversation | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [expandedVideoTitle, setExpandedVideoTitle] = useState<{ [key: string]: boolean }>({});
  const [activeChatId, setActiveChatId] = useState<number | null>(null); // Track active chat ID
  const [activeVideoTitle, setActiveVideoTitle] = useState<string | null>(null); // Track active project name

  const handleItemClick = (id: string) => {
    setActive(id);
  };

  // "fetchChatHistory" is a function responsible for fetching the user's entire chat history from the backend.
  const fetchChatHistory = async () => {
    if (userId) {
      try {
        const response = await fetch(`https://madai.app/chatfun/api/get_conversations?user_id=${userId}`);
        const data = await response.json();
        setChatHistories(data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    }
  };

  const handleChatClick = async (chatId: number, videoTitle: string) => {
    setActiveChatId(chatId); // Set the clicked chat as active
    setActiveVideoTitle(videoTitle); // Set the clicked chat's project as active
    try {
      const response = await fetch(`https://madai.app/chatfun/api/get_conversation?conversation_id=${chatId}`);
      const data = await response.json();

      {/* ""selectedChat"" is a state variable that stores 
      the details of the currently selected chat conversation. */} 
      if (data) {
        setSelectedChat({    
          ...data,
          videoTitle: data.name.split(' ')[0],
          video_id: data.video_id,
          video_filename: data.video_filename,
        });
      }
    } catch (error) {
      console.error('Error fetching chat conversation:', error);
    }
  };

  // Group the chat histories by project name
  const groupChatsByVideoTitle = (chats: Conversation[]) => {
    return chats.reduce((acc: { [key: string]: Conversation[] }, chat: Conversation) => {
      const videoTitle = chat.name.split(' ')[0];
      if (!acc[videoTitle]) {
        acc[videoTitle] = [];
      }
      acc[videoTitle].push(chat);
      return acc;
    }, {});
  };

  // Handle expanding or collapsing project chat lists
  const toggleVideoTitle = (videoTitle: string) => {
    setExpandedVideoTitle((prev) => ({
      ...prev,
      [videoTitle]: !prev[videoTitle],
    }));
  };

  useEffect(() => {
    if (userId) {
      fetchChatHistory();
    }
  }, [userId]);

  const groupedChats = groupChatsByVideoTitle(chatHistories);

  return (
    <aside className="h-screen flex flex-row">

    {/* Side Bar */}
      <nav className={`h-full flex flex-col shadow-sm transition-all duration-200  ${expanded ? "w-56 bg-gray-100" : "w-15 bg-white"}`}>
        {/* Top content of the Side Bar */}
        <div className="p-4 pb-2 flex justify-between items-center h-[10%]">
          <img
            src={MadAI}
            width={22}
            height={40}
            alt="madai"
            className={`overflow-hidden transition-all ${expanded ? "w-20" : "w-0"}`}
          />
          <button
            onClick={() => setExpanded((curr) => !curr)}
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
            className={`p-1.5 rounded-lg bg-gray-100 hover:bg-gray-300 ${expanded ? "" : ""}`}
          >
            <BsLayoutSidebarInsetReverse />
          </button>
          {isTooltipVisible && (
            <div className={`absolute top-6  transform -translate-x-[10px] px-2 py-1 bg-black text-white text-sm rounded-md shadow-lg whitespace-nowrap ${expanded ? "md:left-[4%] lg:left-[15%]" : "md:left-[10%] lg:left-[4%]"}`}>
              Sidebar
            </div>
          )}
        </div>
        {/* Close Top*/}

       {/* Middle content of the Side Bar */}
        <ul className={`h-[20%] flex-3 px-4 p-3 ${expanded ? "" : "opacity-0 w-0"}`}>
          {SideBarCom.map((item) => {
            const Icon: IconType = item.iconUrl;
            return (
              <li key={item.id}>
                <a
                  href={item.url}
                  onClick={() => handleItemClick(item.id)}
                  className={`relative flex items-center py-2 px-7 my-1 gap-4 text-sm
                    font-normal rounded-md cursor-pointer transition-colors
                    ${active === item.id ? "bg-black text-white" : "hover:bg-gray-300 text-neutral-950"}`}
                >
                  <Icon className="mr-2" />
                  <span className={`w-10 ml-3 ${expanded ? "" : "opacity-0"}`}>{item.title}</span>
                </a>
              </li>
            );
          })}
        </ul>
        {/* Close Middle*/}

        {/* Bottom content of the Side Bar */}
        <div className={`h-[75%] flex-1 bg-zinc-50 ${expanded ? "" : "opacity-0"}`}>
          <div className="p-2 rounded-md h-[90%] overflow-y-scroll">
            <ul>
              {Object.keys(groupedChats).length > 0 ? (
                Object.keys(groupedChats).map((videoTitle) => (
                  <li key={videoTitle}>
                    <div
                      className={`py-2 rounded px-7 my-1 flex items-center gap-4 font-medium text-xs cursor-pointer
                        ${expandedVideoTitle[videoTitle] || activeVideoTitle === videoTitle ? "bg-gray-400 text-white" : "bg-gray-200 hover:bg-gray-300"}`}
                      onClick={() => toggleVideoTitle(videoTitle)} // Toggle expand/collapse
                    >
                      {videoTitle}
                    </div>
                    {expandedVideoTitle[videoTitle] && (
                      <ul className="pl-6">
                        {groupedChats[videoTitle].map((history) => (
                          <li
                            key={history.id}
                            className={`py-1 rounded px-7 my-1 flex items-center gap-4 font-medium text-xs cursor-pointer
                              transition-colors ${activeChatId === history.id ? "bg-gray-500 text-white" : "bg-gray-200 hover:bg-gray-300"}`}
                            onClick={() => handleChatClick(history.id, videoTitle)} // Call handleChatClick on click
                          >
                            {history.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
              ) : (
                <li>No chat history found</li>
              )}
            </ul>
          </div>
        </div>
        {/* Close Bottom*/}
      </nav>
      {/* Close Side Bar */}

      {/* Other Content */}
      <div className="p-0 flex-1">

      {/* "Outlet" from react-router-dom is used for nested routing, 
      allowing child components to access the context. */}
        <Outlet
          context={{
            fetchChatHistory,
            selectedChat,
            videoId: selectedChat?.video_id ?? uploadedVideoId,
            videoFileName: selectedChat?.video_filename ?? uploadedVideoId,
            videoTitle: selectedChat?.name.split(" ")[0] ?? uploadedVideoId,
          }}
        />
      </div>
      {/*Close Other Content */}
    </aside>
  );
};

export default Layout;

