import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useEmotionData } from "../../../context/EmotionDataContext"; // Adjust path as per your project structure

const emotionColors: { [key: string]: string } = {
  "Adbhuta (Astonishment)": "#800080",
  "Bibhatsa (Disgust)": "#A52A2A",
  "Bhayanaka (Fear)": "#000000",
  "Hasya (Joy)": "#9ACD32",
  "Karuna (Sorrow)": "#87CEEB",
  "Roudra (Anger)": "#FF0000",
  "Santa (Peace)": "#0000FF",
  "Srungara (Love)": "#FFC0CB",
  "Veera (Confidence)": "#FFD700",
};

const EmotionBarChart: React.FC = () => {
  const { emotionData } = useEmotionData();

  if (!emotionData) {
    return <div>No emotion data available</div>;
  }

  // Calculate the sum for each emotion
  const emotions = Object.keys(emotionColors); // Labels for x-axis
  const emotionSums = emotions.map((emotion) => {
    const emotionValues = Object.values(emotionData[emotion] || {});
    return emotionValues.reduce((sum, value) => sum + value, 0); // Sum all values
  });

  return (
    <div className="flex flex-col items-center justify-center bg-gray-900 p-4 rounded-lg shadow-lg text-white">
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: emotions, // Map emotions as x-axis labels
            label: "Emotions", // Label for the x-axis
            tickFontSize: 14, // Customize tick font size
          },
        ]}
        yAxis={[
          {
            label: "Emotion Scores", // Label for the y-axis
            tickFontSize: 14, // Customize tick font size
          },
        ]}
        series={[
          {
            data: emotionSums, // Array of numbers for the Y-axis
            label: "Sum of Emotions",
          },
        ]}
        width={900}
        height={600}
        sx={{
          "& .MuiChartAxis-root .MuiChartAxis-tickLabel": {
            fill: "white", // Axis tick labels color
          },
          "& .MuiChartAxis-root .MuiChartAxis-label": {
            fill: "white", // Axis main label color
          },
          "& .MuiChartAxis-root .MuiChartAxis-tickLine": {
            stroke: "white", // Axis ticks color
          },
          "& .MuiChartAxis-root .MuiChartAxis-line": {
            stroke: "white", // Axis lines color
          },
        }}
        className="w-full h-auto bg-gray-800 rounded-md p-4"
      />
    </div>
  );
};

export default EmotionBarChart;
