import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "src/components/MadAI/layouts/Layout";
import ChatComponent from "src/components/MadAI/ChatFun.components";
import { useUser } from "src/hooks/useUser";

const MadaiRoutes: React.FC = () => {
  const { userid, videoId, videoTitle } = useUser();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            userId={userid}
            videoTitle={videoTitle}
            uploadedVideoId={videoId}
          />
        }
      >
        <Route
          index
          element={
            <ChatComponent
              userId={userid}
              videoTitle={videoTitle}
              uploadedVideoId={videoId}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default MadaiRoutes;

