import React, { useState } from 'react';
import MadAI from "../../assets/MAD-03.png"
import { navigationLogin } from "../../constants/index";
import { useLocation } from 'react-router-dom';
import MenuSvg from '../../assets/svg/MenuSvg';
import { HambugerMenu } from './design/Header';
import Button from './Button.component';
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { useAuth, logout } from '../Authentication/Auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const pathname = useLocation();
  const navigate = useNavigate(); // To navigate to login on token expiration
  const [openNavigation, setOpenNavigation] = useState<boolean>(false);
  const [logged] = useAuth();
  const [username, setUserName] = useState<string | null>(null);

     // Function to check if token is expired
    const isTokenExpired = (token: string): boolean => {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decodedToken.exp < currentTime; // Check if token is expired
      } catch (error) {
        console.error("Error decoding token:", error);
        return true; // If an error occurs, consider the token as expired
      }
    };

    useEffect(() => {
      // On component mount, check if the user is logged in and the token is still valid
      const token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
      if (logged && token) {
        if (isTokenExpired(token)) {
          handleLogout(); // If token is expired, log the user out
        } else {
          setUserName(localStorage.getItem('username'));
        }
      }
    }, [logged]);

      // Logout function
  const handleLogout = () => {
    logout(); // Clear tokens and other user data
    localStorage.removeItem('REACT_TOKEN_AUTH_KEY');
    localStorage.removeItem('username');
    navigate('/'); // Redirect to login page
    enablePageScroll(); // Ensure scrolling is enabled after logout
    setOpenNavigation(false);
  };

  useEffect(() => {
    if (logged) {
      setUserName(localStorage.getItem('username'));
    }
  }, [logged])

  console.log('Current logged state:', logged); // Debugging

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };
  const handleClick = () => {
    if (!openNavigation) return;

    enablePageScroll();
    setOpenNavigation(false);

  };

  return (
    <div
      className={`fixed top-0 left-0 w-full z-50 border-b border-n-2 lg:bg-n-2/90 lg:backdrop-blur-sm 
                  ${openNavigation ? "bg-n-2" : "bg-n-2/90 backdrop-blur-sm"}`}
    >
      <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-5">
        <a className="block w-[12rem] xl:mr-8" href="/">
          <img src={MadAI} width={70} height={90} alt="logo" />
        </a>

        {/* <span className='flex px-3 max-lg:py-1 fs'>MadAI</span> */}

        <nav
          className={` ${
            openNavigation ? "flex " : "hidden"
          } fixed top-[7rem] left-0 right-0 bottom-0 bg-n-1
        lg:static lg:flex lg:mx-auto lg:bg-transparent`}
        >
          <div
            className={`${
              openNavigation ? "flex " : "hidden"
            }z-2 flex flex-col items-center justify-center m-auto lg:flex-row  -mr-[14rem]`}
          >
            {navigationLogin.map((item) => (
              <a
                key={item.id}
                href={item.url}
                onClick={handleClick}
                className={`block relative font-code text-2xl uppercase
                  text-n-8 transition-colors hover:text-color-7
                  px-6 md:py-8 lg:-mr-0.25
                  lg:text-xs lg:font-semibold 
                  ${
                    item.url === pathname.hash
                      ? "z-2 lg:text-neutral-950"
                      : "lg:text-n-8/50"
                  } 
                  lg:leading-5 lg:hover:text-n-5 xl:px-6`}
              >
                {item.title}
              </a>
            ))}
            {openNavigation &&
              (logged ? (
                <>
                  <a
                    href=""
                    className="relative font-code text-2xl uppercase
                  text-white transition-colors hover:text-color-7
                  px-6 md:py-2 lg:-ml-0.25 xl:px-8 lg:leading-5
                  lg:text-xs lg:font-semibold rounded-full bg-black"
                  >
                    {username || "User"}
                  </a>
                  <a
                    href=""
                    className="relative font-code text-2xl uppercase
                  text-white transition-colors hover:text-color-7
                  px-4 md:py-2 lg:-mr-0.25 xl:px-6 lg:leading-5
                  lg:text-xs lg:font-semibold rounded-full bg-black"
                    onClick={handleLogout}
                  >
                    Log out
                  </a>
                </>
              ) : (
                <a
                  href="/login"
                  className="block relative font-code text-2xl uppercase
                text-n-8 transition-colors hover:text-color-7
                px-6 py-6 md:py-8 lg:-mr-0.25
                lg:text-xs lg:font-semibold"
                >
                  Sign in
                </a>
              ))}
          </div>
          <HambugerMenu />
        </nav>
        <div className={"z-2 flex flex-col  m-auto lg:flex-row mr-2 gap-4"}>
          {logged ? (
            <>
              <a
                href=""
                className={`block  relative font-code text-2xl uppercase
                  text-white transition-colors hover:text-color-7
                  px-6 md:py-2 lg:-ml-0.25 xl:px-8 lg:leading-5
                  lg:text-xs lg:font-semibold rounded-full bg-black`}
              >
                {username || "User"}
              </a>
              <a
                href=""
                className="block relative font-code text-2xl uppercase
                  text-white transition-colors hover:text-color-7
                  px-4 md:py-2 lg:-mr-0.25 xl:px-6 lg:leading-5
                  lg:text-xs lg:font-semibold rounded-full bg-black"
                onClick={handleLogout}
              >
                Log out
              </a>
            </>
          ) : (
            <a
              href="/login"
              className="block relative font-code text-2xl uppercase
                  text-white transition-colors hover:text-color-7
                  px-6 md:py-2 lg:-mr-0.25 xl:px-8 lg:leading-5
                  lg:text-xs lg:font-semibold rounded-full bg-black"
            >
              sign in
            </a>
          )}
        </div>

        <Button
          className="ml-auto lg:hidden"
          px="px-3"
          onClick={toggleNavigation}
        >
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
