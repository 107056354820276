import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import "../../styles/madai.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEmotionData } from "../../context/EmotionDataContext";

interface MadaiFormProps {
  onVideoUpload: (videoPath: string, videoId: string, emotionData: any) => void;
}

const MadaiForm: React.FC<MadaiFormProps> = ({ onVideoUpload }) => {
  const { setEmotionData, setPredictedRagaName } = useEmotionData();

  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<Record<string, any>> = async (data) => {
    const formData = new FormData();
    // formData.append('projectname', data.projectname);
    formData.append("videotitle", data.videotitle);
    formData.append("videofile", data.videofile[0]);

    // Append CSV file only if it exists
    if (data.csvfile && data.csvfile.length > 0) {
      formData.append("csvfile", data.csvfile[0]);
    }

    setIsLoading(true); // Start loading animation when the submit button is clicked

    try {
      const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
      if (token) {
        const response = await fetch(
          "https://madai.app/algorithm/api/video-csv",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
            body: formData,
          }
        );
        const result = await response.json();
        if (response.ok) {
          // Ensure emotion data and predicted raga name are set correctly
          if (result.emotion_data) {
            setEmotionData(result.emotion_data);
          } else {
            console.error("Emotion data missing from response");
          }

          if (result.predicted_raga_name) {
            setPredictedRagaName(result.predicted_raga_name);
          } else {
            console.error("Predicted Raga Name missing from response");
          }

          console.log("emotion_data", result.emotion_data);
          console.log("Video ID:", result.video_id);
          localStorage.setItem("videoTitle", result.video_title);
          localStorage.setItem("videoId", result.video_id);
          localStorage.setItem("emotion_data", result.emotion_data);
          localStorage.setItem(
            "emotion_data",
            JSON.stringify(result.emotion_data)
          );
          localStorage.setItem(
            "predicted_raga_name",
            result.predicted_raga_name
          );
          localStorage.setItem(
            "sum_emotions",
            JSON.stringify(result.sum_emotions)
          );
          localStorage.setItem("sum_emotions", result.sum_emotions);

          // Clear the form after successful submission
          reset();
          console.log("emotion_data", result.emotion_data);
          // Notify parent component of the uploaded video filename
          onVideoUpload(
            result.video_filename,
            result.video_id,
            result.emotion_data
          );

          // Reload the page after 3 seconds (to allow the alert to display)
          setTimeout(() => {
            window.location.reload();
          }, 3000); // 3-second delay before reload
          // window.location.reload();
          toast.success("File upload successful.", {
            position: "top-center",
          });
        } else {
          toast.error("Failed to upload. Please try again.", {
            position: "top-center",
          });
          console.error("Upload error:", result.error);
        }
      } else {
        toast.error("Authorization token is missing");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to upload. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading animation after the file upload process completes
    }
  };

  return (
    <div>
      <ToastContainer />
      <form
        className="bg-white shadow-md rounded px-4 pt-4 relative flex items-center justify-center"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className={`${isLoading ? "opacity-50" : ""}`}>
          {/* <div className="mb-4 mt-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projectname">
                            Project Name
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="projectname"
                            type="text"
                            {...register('projectname', { required: true })}
                        />
                    </div> */}
          <div className="mb-4 mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="videotitle"
            >
              Video Title
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="videotitle"
              type="text"
              {...register("videotitle", { required: true })}
            />
          </div>
          <div className="mb-4 mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="videofile"
            >
              Upload Video
            </label>
            <input
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-black file:text-white hover:file:bg-gray-800"
              id="videofile"
              type="file"
              accept="video/*"
              {...register("videofile", { required: true })}
            />
          </div>
          <div className="mb-4 mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="csvfile"
            >
              Upload CSV (Optional)
            </label>
            <input
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-black file:text-white hover:file:bg-gray-800"
              id="csvfile"
              type="file"
              accept=".csv"
              {...register("csvfile")}
            />
          </div>

          <div className="flex items-center justify-center mt-8 mb-8">
            <button
              className={`bg-black hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              type="submit"
              disabled={isLoading} // Disable the button while loading
            >
              {isLoading ? "Uploading..." : "Submit"}
            </button>
          </div>
        </div>

        {isLoading && (
          // <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          //     <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12"></div>
          // </div>
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
            <button
              type="button"
              className="bg-black text-white px-4 py-2 rounded flex items-center justify-center "
              disabled
            >
              <svg
                className="animate-spin h-5 w-5 mr-3 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C6.48 0 0 6.48 0 12h4zm2 5.291l-1.707 1.707A7.965 7.965 0 004 20.728V16h2v1.291z"
                ></path>
              </svg>
              Processing...
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default MadaiForm;

