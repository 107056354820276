import React from "react";
import {
  Routes,
  Route,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import Hero from "src/components/Home/Hero.component";
import Why from "src/components/Home/Features";
import HowtoUse from "src/components/Home/HowtoUse.component";
import Pricing from "src/components/Home/Pricing.components";
import Footer from "src/components/Home/Footer.components";
// import ToothColorDetection from 'src/components/Teeth/TeethCam';

function HomeRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Hero />
            <Why />
            <HowtoUse />
            <Pricing />
            <Footer />
          </>
        }
      />
    </Routes>
  );
}

export default HomeRoutes;

