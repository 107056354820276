import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

interface CustomCardProps {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

interface VideoProps extends CustomCardProps {
  videoId: string;
}

const Video: React.FC<VideoProps> = () => {
  const { videoId, videoFileName } = useOutletContext<{
    videoId: string;
    videoFileName: string;
  }>(); // Get videoId from the Outlet context
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideo = async () => {
      if (!videoId) return; // Don't fetch if no videoId available

      try {
        const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
        if (token) {
          // Token handling logic can go here
        }
        const response = await fetch(
          `https://madai.app/video/api/uploadvideo/${videoId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const videoBlob = await response.blob();
        const videoObjectUrl = URL.createObjectURL(videoBlob);
        setVideoUrl(videoObjectUrl); // Set the video URL to display in the video element
      } catch (error) {
        console.error("Failed to fetch video:", error);
        alert(
          "Failed to fetch video. Please check the console for more details."
        );
      }
    };

    fetchVideo();
  }, [videoId]); // Re-fetch the video when videoId changes

  useEffect(() => {
    console.log("Video ID:", videoId); // Check if videoId is passed correctly
    console.log("Video File Name:", videoFileName);
  }, [videoId, videoFileName]);

  return (
    <div className="w-full flex flex-col h-auto">
      {videoUrl ? (
        <video
          controls
          className="w-full flex flex-col h-auto"
          width="5" // Fixed width value in pixels
          height="300" // Fixed height value in pixels
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      ) : (
        <video controls width="640" height="360">
          No video available pl
        </video>
      )}
    </div>
  );
};

export default Video;

