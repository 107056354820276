import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { login, logout } from "./Auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { signInWithGoogle } from "src/utils/firebase/firebase.utils";
import { FaHome, FaGooglePlusG } from "react-icons/fa";

interface IFormInput {
  email: string;
  password: string;
}

interface InputProps {
  id: string;
  label: string;
  placeholder: string;
}

const TempLogin: React.FC<InputProps> = ({ id, label, placeholder }) => {
  const [isSignInButtonClicked, setIsSignInButtonClicked] = useState(false);
  const [showSignInPassword, setShowSignInPassword] = useState(false); // State to toggle SignIn password visibility
  const navigate = useNavigate();
  const tokenExpiryCheckInterval = 60 * 1000; // Check token expiry every 1 minute

  const signIn = () => {
    setIsSignInButtonClicked(!isSignInButtonClicked);
  };

  const {
    register: registerSignIn,
    handleSubmit: handleSubmitSignIn,
    setValue: setValueSignIn,
    formState: { errors: errorsSignIn },
    reset,
  } = useForm<IFormInput>();

  const onTempLogin: SubmitHandler<IFormInput> = async (data) => {
    try {
      const response = await fetch('https://madai.app/auth/api/templogin', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("REACT_TOKEN_AUTH_KEY", responseData.access_token);
        localStorage.setItem(
          "ACCESS_TOKEN_EXPIRY",
          (Date.now() + 12 * 60 * 60 * 1000).toString()
        ); // Assuming 15 minutes expiry
        localStorage.setItem("REFRESH_TOKEN_KEY", responseData.refresh_token);
        localStorage.setItem("username", responseData.user_name);
        localStorage.setItem("user_id", responseData.user_id);
        const Role = localStorage.setItem("user_role", responseData.role);
        console.log("Login successful");
        // console.log('Token:', token);  // Debugging token
        console.log("Role:", responseData.role);
        login(responseData.access_token);
        // Redirect based on user role
        if (responseData.role === "admin") {
          navigate("/admin"); // Redirect to admin dashboard
        } else {
          navigate("/madai"); // Redirect to user dashboard
        }
      } else {
        const errorData = await response.json();
        handleErrorMessageForTemLogin(errorData, setValueSignIn, reset);
        console.error("Login failed", errorData.message);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      // alert('An error occurred while logging in. Please try again later.');
      toast.error(
        "An error occurred while logging in. Please try again later."
      );
    }
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("REFRESH_TOKEN_KEY");
      if (!refreshToken) throw new Error("No refresh token available");

      const response = await fetch("https://madai.app/auth/api/refresh", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken }),
      });

      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("REACT_TOKEN_AUTH_KEY", responseData.access_token);
        localStorage.setItem(
          "ACCESS_TOKEN_EXPIRY",
          (Date.now() + 12 * 60 * 60 * 1000).toString()
        );
        localStorage.setItem("user_id", responseData.user_id);

        login(responseData.access_token);
      } else {
        logout();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error refreshing access token:", error);
      logout();
      navigate("/login");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
    const tokenExpiry = localStorage.getItem("ACCESS_TOKEN_EXPIRY");

    const checkTokenExpiration = () => {
      if (token && tokenExpiry) {
        const now = Date.now();
        if (now > parseInt(tokenExpiry)) {
          refreshAccessToken();
        }
      } else {
        logout();
        navigate("/login");
      }
    };

    checkTokenExpiration();
    const interval = setInterval(
      checkTokenExpiration,
      tokenExpiryCheckInterval
    );

    return () => clearInterval(interval);
  }, [navigate]);

  const handleErrorMessageForTemLogin = (
    errorData: any,
    setFieldValue: any,
    reset: any
  ) => {
    if (errorData.message === "Email not found") {
      toast.error("The email address you entered is not registered.", {
        position: "top-center",
      });
      setFieldValue("email", ""); // Reset the email input field only
    } else if (errorData.message === "Incorrect password") {
      toast.error("The password you entered is incorrect.", {
        position: "top-center",
      });
      setFieldValue("password", ""); // Reset the name input field only
    } else {
      toast.error("An unknown error occurred");
      reset(); // Optional: reset the whole form if needed
    }
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-cover bg-center">
      <div className="relative w-[400px] h-[500px] shadow-lg overflow-hidden rounded-lg bg-gray-300">
        <form onSubmit={handleSubmitSignIn(onTempLogin)}>
          <ToastContainer />
          <div
            className={`absolute top-0 w-[100%] h-full flex flex-col items-center justify-center p-10`}
          >
            <h1 className="text-2xl font-bold text-black mb-5">Sign in</h1>
            <div className="flex space-x-4 mb-5">
              <a
                href="/"
                className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-black"
              >
                <FaHome />
              </a>

            {/*  <a
                href="#"
                className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-200 text-red-900"
                onClick={signInWithGoogle}
              >
                <FaGooglePlusG />
              </a>  */}
            </div>
            <span className="text-sm text-black mb-5">or use your account</span>
            <div className="w-full">
              <input
                type="email"
                id="signin-email"
                placeholder="Email"
                className={`w-full p-3 bg-gray-200 rounded ${
                  isSignInButtonClicked ? "my-1" : "my-1"
                }`}
                {...registerSignIn("email", {
                  required: "Email is required",
                  maxLength: {
                    value: 60,
                    message: "Email cannot exceed 60 characters",
                  },
                })}
              />
              {errorsSignIn.email && (
                <span style={{ color: "red", fontSize: "8px" }}>
                  {errorsSignIn.email.message}
                </span>
              )}

              <input
                type={showSignInPassword ? "text" : "password"}
                id="signin-password"
                placeholder="Password"
                className={`w-full p-3 bg-gray-200 rounded ${
                  isSignInButtonClicked ? "my-1" : "my-1"
                }`}
                {...registerSignIn("password", {
                  required: "Password is required",
                  maxLength: {
                    value: 14,
                    message: "Password cannot exceed 14 characters",
                  },
                })}
              />
              {errorsSignIn.password && (
                <span style={{ color: "red", fontSize: "8px" }}>
                  {errorsSignIn.password.message}
                </span>
              )}
              <div className="w-full space-x-1 flex p-2 rounded">
                <input
                  type="checkbox"
                  onChange={() => setShowSignInPassword(!showSignInPassword)}
                />
                <span className="text-xs">Show Password</span>
              </div>
            </div>

            {/* <div className="relative mb-3" data-twe-input-wrapper-init>
              <input
                type="text"
                className="peer block min-h-[auto] w-full 
                rounded border border-gray-500 bg-gray-300  px-3 py-[0.32rem] 
                leading-[1.6] outline-none transition-all duration-200 
                ease-linear focus:border-black  focus:placeholder:opacity-100 
                peer-focus:text-primary 
                data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none
                 [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                id={id}
                placeholder={placeholder}
              />
              <label
                htmlFor={id}
                className="pointer-events-none absolute 
                left-3 top-0 mb-0 max-w-[90%] origin-[0_0] 
                truncate pt-[0.37rem] leading-[1.6] text-neutral-500 
                transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] 
                peer-focus:scale-[0.8] peer-focus:bg-gray-300
                peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] 
                peer-data-[twe-input-state-active]:scale-[0.8] 
                motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
              >
                Example label
              </label>
            </div> */}

            <a href="#" className="text-red-600 text-xs underline mt-3 mb-5">
              Forgot your password?
            </a>
            <button
              type="submit"
              className="px-12 py-2 bg-gray-800 text-white rounded-full"
              onClick={signIn}
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TempLogin;
